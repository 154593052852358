var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f7dd7c811d52fdc861d92527a38694621a685be5"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN
const environment = process.env.NEXT_PUBLIC_APP_ENV

if (environment !== 'local') {
    Sentry.init({
        dsn,
        environment,
        tracesSampleRate: 0.05, // 5% of all transactions will be captured
        ignoreErrors: [
            'Invariant: attempted to hard navigate to the same URL',
            // https://github.com/getsentry/sentry/issues/61469
            'null is not an object (evaluating \'Object.prototype.hasOwnProperty.call(o,"telephone")\')',
        ],
    })
}
